import _Number from "./Number";
import _utils from "./utils";
import _buffer from "buffer";

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};
var Buffer = _buffer.Buffer;
// Generated by CoffeeScript 1.7.1
(function () {
  var NumberT, StringT, utils;
  NumberT = _Number.Number;
  utils = _utils;

  StringT = function () {
    function StringT(length, encoding) {
      (this || _global).length = length;
      (this || _global).encoding = encoding != null ? encoding : "ascii";
    }

    StringT.prototype.decode = function (stream, parent) {
      var buffer, encoding, length, pos, string;

      length = function () {
        if ((this || _global).length != null) {
          return utils.resolveLength((this || _global).length, stream, parent);
        } else {
          buffer = stream.buffer, length = stream.length, pos = stream.pos;

          while (pos < length && buffer[pos] !== 0) {
            ++pos;
          }

          return pos - stream.pos;
        }
      }.call(this || _global);

      encoding = (this || _global).encoding;

      if (typeof encoding === "function") {
        encoding = encoding.call(parent, parent) || "ascii";
      }

      string = stream.readString(length, encoding);

      if ((this || _global).length == null && stream.pos < stream.length) {
        stream.pos++;
      }

      return string;
    };

    StringT.prototype.size = function (val, parent) {
      var encoding, size;

      if (!val) {
        return utils.resolveLength((this || _global).length, null, parent);
      }

      encoding = (this || _global).encoding;

      if (typeof encoding === "function") {
        encoding = encoding.call(parent != null ? parent.val : void 0, parent != null ? parent.val : void 0) || "ascii";
      }

      if (encoding === "utf16be") {
        encoding = "utf16le";
      }

      size = Buffer.byteLength(val, encoding);

      if ((this || _global).length instanceof NumberT) {
        size += (this || _global).length.size();
      }

      if ((this || _global).length == null) {
        size++;
      }

      return size;
    };

    StringT.prototype.encode = function (stream, val, parent) {
      var encoding;
      encoding = (this || _global).encoding;

      if (typeof encoding === "function") {
        encoding = encoding.call(parent != null ? parent.val : void 0, parent != null ? parent.val : void 0) || "ascii";
      }

      if ((this || _global).length instanceof NumberT) {
        (this || _global).length.encode(stream, Buffer.byteLength(val, encoding));
      }

      stream.writeString(val, encoding);

      if ((this || _global).length == null) {
        return stream.writeUInt8(0);
      }
    };

    return StringT;
  }();

  exports = StringT;
}).call(exports);
export default exports;