var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};
// Generated by CoffeeScript 1.7.1
(function () {
  var Bitfield;

  Bitfield = function () {
    function Bitfield(type, flags) {
      (this || _global).type = type;
      (this || _global).flags = flags != null ? flags : [];
    }

    Bitfield.prototype.decode = function (stream) {
      var flag, i, res, val, _i, _len, _ref;

      val = (this || _global).type.decode(stream);
      res = {};
      _ref = (this || _global).flags;

      for (i = _i = 0, _len = _ref.length; _i < _len; i = ++_i) {
        flag = _ref[i];

        if (flag != null) {
          res[flag] = !!(val & 1 << i);
        }
      }

      return res;
    };

    Bitfield.prototype.size = function () {
      return (this || _global).type.size();
    };

    Bitfield.prototype.encode = function (stream, keys) {
      var flag, i, val, _i, _len, _ref;

      val = 0;
      _ref = (this || _global).flags;

      for (i = _i = 0, _len = _ref.length; _i < _len; i = ++_i) {
        flag = _ref[i];

        if (flag != null) {
          if (keys[flag]) {
            val |= 1 << i;
          }
        }
      }

      return (this || _global).type.encode(stream, val);
    };

    return Bitfield;
  }();

  exports = Bitfield;
}).call(exports);
export default exports;