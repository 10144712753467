import _Array from "./Array";
import _Number from "./Number";
import _utils from "./utils";
import _util from "util";

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};
// Generated by CoffeeScript 1.7.1
(function () {
  var ArrayT,
      LazyArray,
      LazyArrayT,
      NumberT,
      inspect,
      utils,
      __hasProp = {}.hasOwnProperty,
      __extends = function (child, parent) {
    for (var key in parent) {
      if (__hasProp.call(parent, key)) child[key] = parent[key];
    }

    function ctor() {
      (this || _global).constructor = child;
    }

    ctor.prototype = parent.prototype;
    child.prototype = new ctor();
    child.__super__ = parent.prototype;
    return child;
  };

  ArrayT = _Array;
  NumberT = _Number.Number;
  utils = _utils;
  inspect = _util.inspect;

  LazyArrayT = function (_super) {
    __extends(LazyArrayT, _super);

    function LazyArrayT() {
      return LazyArrayT.__super__.constructor.apply(this || _global, arguments);
    }

    LazyArrayT.prototype.decode = function (stream, parent) {
      var length, pos, res;
      pos = stream.pos;
      length = utils.resolveLength((this || _global).length, stream, parent);

      if ((this || _global).length instanceof NumberT) {
        parent = {
          parent: parent,
          _startOffset: pos,
          _currentOffset: 0,
          _length: length
        };
      }

      res = new LazyArray((this || _global).type, length, stream, parent);
      stream.pos += length * (this || _global).type.size(null, parent);
      return res;
    };

    LazyArrayT.prototype.size = function (val, ctx) {
      if (val instanceof LazyArray) {
        val = val.toArray();
      }

      return LazyArrayT.__super__.size.call(this || _global, val, ctx);
    };

    LazyArrayT.prototype.encode = function (stream, val, ctx) {
      if (val instanceof LazyArray) {
        val = val.toArray();
      }

      return LazyArrayT.__super__.encode.call(this || _global, stream, val, ctx);
    };

    return LazyArrayT;
  }(ArrayT);

  LazyArray = function () {
    function LazyArray(type, length, stream, ctx) {
      (this || _global).type = type;
      (this || _global).length = length;
      (this || _global).stream = stream;
      (this || _global).ctx = ctx;
      (this || _global).base = (this || _global).stream.pos;
      (this || _global).items = [];
    }

    LazyArray.prototype.get = function (index) {
      var pos;

      if (index < 0 || index >= (this || _global).length) {
        return void 0;
      }

      if ((this || _global).items[index] == null) {
        pos = (this || _global).stream.pos;
        (this || _global).stream.pos = (this || _global).base + (this || _global).type.size(null, (this || _global).ctx) * index;
        (this || _global).items[index] = (this || _global).type.decode((this || _global).stream, (this || _global).ctx);
        (this || _global).stream.pos = pos;
      }

      return (this || _global).items[index];
    };

    LazyArray.prototype.toArray = function () {
      var i, _i, _ref, _results;

      _results = [];

      for (i = _i = 0, _ref = (this || _global).length; _i < _ref; i = _i += 1) {
        _results.push(this.get(i));
      }

      return _results;
    };

    LazyArray.prototype.inspect = function () {
      return inspect(this.toArray());
    };

    return LazyArray;
  }();

  exports = LazyArrayT;
}).call(exports);
export default exports;