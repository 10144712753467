import _utils from "./utils";

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};
// Generated by CoffeeScript 1.7.1
(function () {
  var Struct, utils;
  utils = _utils;

  Struct = function () {
    function Struct(fields) {
      (this || _global).fields = fields != null ? fields : {};
    }

    Struct.prototype.decode = function (stream, parent, length) {
      var res, _ref;

      if (length == null) {
        length = 0;
      }

      res = this._setup(stream, parent, length);

      this._parseFields(stream, res, (this || _global).fields);

      if ((_ref = (this || _global).process) != null) {
        _ref.call(res, stream);
      }

      return res;
    };

    Struct.prototype._setup = function (stream, parent, length) {
      var res;
      res = {};
      Object.defineProperties(res, {
        parent: {
          value: parent
        },
        _startOffset: {
          value: stream.pos
        },
        _currentOffset: {
          value: 0,
          writable: true
        },
        _length: {
          value: length
        }
      });
      return res;
    };

    Struct.prototype._parseFields = function (stream, res, fields) {
      var key, type, val;

      for (key in fields) {
        type = fields[key];

        if (typeof type === "function") {
          val = type.call(res, res);
        } else {
          val = type.decode(stream, res);
        }

        if (val !== void 0) {
          if (val instanceof utils.PropertyDescriptor) {
            Object.defineProperty(res, key, val);
          } else {
            res[key] = val;
          }
        }

        res._currentOffset = stream.pos - res._startOffset;
      }
    };

    Struct.prototype.size = function (val, parent, includePointers) {
      var ctx, key, size, type, _ref;

      if (val == null) {
        val = {};
      }

      if (includePointers == null) {
        includePointers = true;
      }

      ctx = {
        parent: parent,
        val: val,
        pointerSize: 0
      };
      size = 0;
      _ref = (this || _global).fields;

      for (key in _ref) {
        type = _ref[key];

        if (type.size != null) {
          size += type.size(val[key], ctx);
        }
      }

      if (includePointers) {
        size += ctx.pointerSize;
      }

      return size;
    };

    Struct.prototype.encode = function (stream, val, parent) {
      var ctx, i, key, ptr, type, _ref, _ref1;

      if ((_ref = (this || _global).preEncode) != null) {
        _ref.call(val, stream);
      }

      ctx = {
        pointers: [],
        startOffset: stream.pos,
        parent: parent,
        val: val,
        pointerSize: 0
      };
      ctx.pointerOffset = stream.pos + this.size(val, ctx, false);
      _ref1 = (this || _global).fields;

      for (key in _ref1) {
        type = _ref1[key];

        if (type.encode != null) {
          type.encode(stream, val[key], ctx);
        }
      }

      i = 0;

      while (i < ctx.pointers.length) {
        ptr = ctx.pointers[i++];
        ptr.type.encode(stream, ptr.val, ptr.parent);
      }
    };

    return Struct;
  }();

  exports = Struct;
}).call(exports);
export default exports;