var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};
// Generated by CoffeeScript 1.7.1
(function () {
  var BooleanT;

  BooleanT = function () {
    function BooleanT(type) {
      (this || _global).type = type;
    }

    BooleanT.prototype.decode = function (stream, parent) {
      return !!(this || _global).type.decode(stream, parent);
    };

    BooleanT.prototype.size = function (val, parent) {
      return (this || _global).type.size(val, parent);
    };

    BooleanT.prototype.encode = function (stream, val, parent) {
      return (this || _global).type.encode(stream, +val, parent);
    };

    return BooleanT;
  }();

  exports = BooleanT;
}).call(exports);
export default exports;