import _utils from "./utils";

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};
// Generated by CoffeeScript 1.7.1
(function () {
  var Reserved, utils;
  utils = _utils;

  Reserved = function () {
    function Reserved(type, count) {
      (this || _global).type = type;
      (this || _global).count = count != null ? count : 1;
    }

    Reserved.prototype.decode = function (stream, parent) {
      stream.pos += this.size(null, parent);
      return void 0;
    };

    Reserved.prototype.size = function (data, parent) {
      var count;
      count = utils.resolveLength((this || _global).count, null, parent);
      return (this || _global).type.size() * count;
    };

    Reserved.prototype.encode = function (stream, val, parent) {
      return stream.fill(0, this.size(val, parent));
    };

    return Reserved;
  }();

  exports = Reserved;
}).call(exports);
export default exports;