import _utils from "./utils";

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};
// Generated by CoffeeScript 1.7.1
(function () {
  var Pointer, VoidPointer, utils;
  utils = _utils;

  Pointer = function () {
    function Pointer(offsetType, type, options) {
      var _base, _base1, _base2, _base3;

      (this || _global).offsetType = offsetType;
      (this || _global).type = type;
      (this || _global).options = options != null ? options : {};

      if ((this || _global).type === "void") {
        (this || _global).type = null;
      }

      if ((_base = (this || _global).options).type == null) {
        _base.type = "local";
      }

      if ((_base1 = (this || _global).options).allowNull == null) {
        _base1.allowNull = true;
      }

      if ((_base2 = (this || _global).options).nullValue == null) {
        _base2.nullValue = 0;
      }

      if ((_base3 = (this || _global).options).lazy == null) {
        _base3.lazy = false;
      }

      if ((this || _global).options.relativeTo) {
        (this || _global).relativeToGetter = new Function("ctx", "return ctx." + (this || _global).options.relativeTo);
      }
    }

    Pointer.prototype.decode = function (stream, ctx) {
      var c, decodeValue, offset, ptr, relative, val;
      offset = (this || _global).offsetType.decode(stream, ctx);

      if (offset === (this || _global).options.nullValue && (this || _global).options.allowNull) {
        return null;
      }

      relative = function () {
        switch ((this || _global).options.type) {
          case "local":
            return ctx._startOffset;

          case "immediate":
            return stream.pos - (this || _global).offsetType.size();

          case "parent":
            return ctx.parent._startOffset;

          default:
            c = ctx;

            while (c.parent) {
              c = c.parent;
            }

            return c._startOffset || 0;
        }
      }.call(this || _global);

      if ((this || _global).options.relativeTo) {
        relative += this.relativeToGetter(ctx);
      }

      ptr = offset + relative;

      if ((this || _global).type != null) {
        val = null;

        decodeValue = function (_this) {
          return function () {
            var pos;

            if (val != null) {
              return val;
            }

            pos = stream.pos;
            stream.pos = ptr;
            val = _this.type.decode(stream, ctx);
            stream.pos = pos;
            return val;
          };
        }(this || _global);

        if ((this || _global).options.lazy) {
          return new utils.PropertyDescriptor({
            get: decodeValue
          });
        }

        return decodeValue();
      } else {
        return ptr;
      }
    };

    Pointer.prototype.size = function (val, ctx) {
      var parent, type;
      parent = ctx;

      switch ((this || _global).options.type) {
        case "local":
        case "immediate":
          break;

        case "parent":
          ctx = ctx.parent;
          break;

        default:
          while (ctx.parent) {
            ctx = ctx.parent;
          }

      }

      type = (this || _global).type;

      if (type == null) {
        if (!(val instanceof VoidPointer)) {
          throw new Error("Must be a VoidPointer");
        }

        type = val.type;
        val = val.value;
      }

      if (val && ctx) {
        ctx.pointerSize += type.size(val, parent);
      }

      return (this || _global).offsetType.size();
    };

    Pointer.prototype.encode = function (stream, val, ctx) {
      var parent, relative, type;
      parent = ctx;

      if (val == null) {
        (this || _global).offsetType.encode(stream, (this || _global).options.nullValue);

        return;
      }

      switch ((this || _global).options.type) {
        case "local":
          relative = ctx.startOffset;
          break;

        case "immediate":
          relative = stream.pos + (this || _global).offsetType.size(val, parent);
          break;

        case "parent":
          ctx = ctx.parent;
          relative = ctx.startOffset;
          break;

        default:
          relative = 0;

          while (ctx.parent) {
            ctx = ctx.parent;
          }

      }

      if ((this || _global).options.relativeTo) {
        relative += this.relativeToGetter(parent.val);
      }

      (this || _global).offsetType.encode(stream, ctx.pointerOffset - relative);

      type = (this || _global).type;

      if (type == null) {
        if (!(val instanceof VoidPointer)) {
          throw new Error("Must be a VoidPointer");
        }

        type = val.type;
        val = val.value;
      }

      ctx.pointers.push({
        type: type,
        val: val,
        parent: parent
      });
      return ctx.pointerOffset += type.size(val, parent);
    };

    return Pointer;
  }();

  VoidPointer = function () {
    function VoidPointer(type, value) {
      (this || _global).type = type;
      (this || _global).value = value;
    }

    return VoidPointer;
  }();

  exports.Pointer = Pointer;
  exports.VoidPointer = VoidPointer;
}).call(exports);
export default exports;