var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};
// Generated by CoffeeScript 1.7.1
(function () {
  var Optional;

  Optional = function () {
    function Optional(type, condition) {
      (this || _global).type = type;
      (this || _global).condition = condition != null ? condition : true;
    }

    Optional.prototype.decode = function (stream, parent) {
      var condition;
      condition = (this || _global).condition;

      if (typeof condition === "function") {
        condition = condition.call(parent, parent);
      }

      if (condition) {
        return (this || _global).type.decode(stream, parent);
      }
    };

    Optional.prototype.size = function (val, parent) {
      var condition;
      condition = (this || _global).condition;

      if (typeof condition === "function") {
        condition = condition.call(parent, parent);
      }

      if (condition) {
        return (this || _global).type.size(val, parent);
      } else {
        return 0;
      }
    };

    Optional.prototype.encode = function (stream, val, parent) {
      var condition;
      condition = (this || _global).condition;

      if (typeof condition === "function") {
        condition = condition.call(parent, parent);
      }

      if (condition) {
        return (this || _global).type.encode(stream, val, parent);
      }
    };

    return Optional;
  }();

  exports = Optional;
}).call(exports);
export default exports;