import _EncodeStream from "./src/EncodeStream";
import _DecodeStream from "./src/DecodeStream";
import _Array from "./src/Array";
import _LazyArray from "./src/LazyArray";
import _Bitfield from "./src/Bitfield";
import _Boolean from "./src/Boolean";
import _Buffer from "./src/Buffer";
import _Enum from "./src/Enum";
import _Optional from "./src/Optional";
import _Reserved from "./src/Reserved";
import _String from "./src/String";
import _Struct from "./src/Struct";
import _VersionedStruct from "./src/VersionedStruct";
import _Number from "./src/Number";
import _Pointer from "./src/Pointer";
var exports = {};
// Generated by CoffeeScript 1.7.1
(function () {
  var key, val, _ref, _ref1;

  exports.EncodeStream = _EncodeStream;
  exports.DecodeStream = _DecodeStream;
  exports.Array = _Array;
  exports.LazyArray = _LazyArray;
  exports.Bitfield = _Bitfield;
  exports.Boolean = _Boolean;
  exports.Buffer = _Buffer;
  exports.Enum = _Enum;
  exports.Optional = _Optional;
  exports.Reserved = _Reserved;
  exports.String = _String;
  exports.Struct = _Struct;
  exports.VersionedStruct = _VersionedStruct;
  _ref = _Number;

  for (key in _ref) {
    val = _ref[key];
    exports[key] = val;
  }

  _ref1 = _Pointer;

  for (key in _ref1) {
    val = _ref1[key];
    exports[key] = val;
  }
}).call(exports);
export default exports;
export const EncodeStream = exports.EncodeStream,
      DecodeStream = exports.DecodeStream,
      LazyArray = exports.LazyArray,
      Bitfield = exports.Bitfield,
      Enum = exports.Enum,
      Optional = exports.Optional,
      Reserved = exports.Reserved,
      Struct = exports.Struct,
      VersionedStruct = exports.VersionedStruct;
const _Array2 = exports.Array,
      _Boolean2 = exports.Boolean,
      _Buffer2 = exports.Buffer,
      _String2 = exports.String;
export { _Array2 as Array, _Boolean2 as Boolean, _Buffer2 as Buffer, _String2 as String };