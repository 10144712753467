import _Number from "./Number";
import _utils from "./utils";

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};
// Generated by CoffeeScript 1.7.1
(function () {
  var ArrayT, NumberT, utils;
  NumberT = _Number.Number;
  utils = _utils;

  ArrayT = function () {
    function ArrayT(type, length, lengthType) {
      (this || _global).type = type;
      (this || _global).length = length;
      (this || _global).lengthType = lengthType != null ? lengthType : "count";
    }

    ArrayT.prototype.decode = function (stream, parent) {
      var ctx, i, length, pos, res, target, _i;

      pos = stream.pos;
      res = [];
      ctx = parent;

      if ((this || _global).length != null) {
        length = utils.resolveLength((this || _global).length, stream, parent);
      }

      if ((this || _global).length instanceof NumberT) {
        Object.defineProperties(res, {
          parent: {
            value: parent
          },
          _startOffset: {
            value: pos
          },
          _currentOffset: {
            value: 0,
            writable: true
          },
          _length: {
            value: length
          }
        });
        ctx = res;
      }

      if (length == null || (this || _global).lengthType === "bytes") {
        target = length != null ? stream.pos + length : (parent != null ? parent._length : void 0) ? parent._startOffset + parent._length : stream.length;

        while (stream.pos < target) {
          res.push((this || _global).type.decode(stream, ctx));
        }
      } else {
        for (i = _i = 0; _i < length; i = _i += 1) {
          res.push((this || _global).type.decode(stream, ctx));
        }
      }

      return res;
    };

    ArrayT.prototype.size = function (array, ctx) {
      var item, size, _i, _len;

      if (!array) {
        return (this || _global).type.size(null, ctx) * utils.resolveLength((this || _global).length, null, ctx);
      }

      size = 0;

      if ((this || _global).length instanceof NumberT) {
        size += (this || _global).length.size();
        ctx = {
          parent: ctx
        };
      }

      for (_i = 0, _len = array.length; _i < _len; _i++) {
        item = array[_i];
        size += (this || _global).type.size(item, ctx);
      }

      return size;
    };

    ArrayT.prototype.encode = function (stream, array, parent) {
      var ctx, i, item, ptr, _i, _len;

      ctx = parent;

      if ((this || _global).length instanceof NumberT) {
        ctx = {
          pointers: [],
          startOffset: stream.pos,
          parent: parent
        };
        ctx.pointerOffset = stream.pos + this.size(array, ctx);

        (this || _global).length.encode(stream, array.length);
      }

      for (_i = 0, _len = array.length; _i < _len; _i++) {
        item = array[_i];

        (this || _global).type.encode(stream, item, ctx);
      }

      if ((this || _global).length instanceof NumberT) {
        i = 0;

        while (i < ctx.pointers.length) {
          ptr = ctx.pointers[i++];
          ptr.type.encode(stream, ptr.val);
        }
      }
    };

    return ArrayT;
  }();

  exports = ArrayT;
}).call(exports);
export default exports;