import _utils from "./utils";
import _Number from "./Number";

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};
// Generated by CoffeeScript 1.7.1
(function () {
  var BufferT, NumberT, utils;
  utils = _utils;
  NumberT = _Number.Number;

  BufferT = function () {
    function BufferT(length) {
      (this || _global).length = length;
    }

    BufferT.prototype.decode = function (stream, parent) {
      var length;
      length = utils.resolveLength((this || _global).length, stream, parent);
      return stream.readBuffer(length);
    };

    BufferT.prototype.size = function (val, parent) {
      if (!val) {
        return utils.resolveLength((this || _global).length, null, parent);
      }

      return val.length;
    };

    BufferT.prototype.encode = function (stream, buf, parent) {
      if ((this || _global).length instanceof NumberT) {
        (this || _global).length.encode(stream, buf.length);
      }

      return stream.writeBuffer(buf);
    };

    return BufferT;
  }();

  exports = BufferT;
}).call(exports);
export default exports;