var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};
// Generated by CoffeeScript 1.7.1
(function () {
  var Enum;

  Enum = function () {
    function Enum(type, options) {
      (this || _global).type = type;
      (this || _global).options = options != null ? options : [];
    }

    Enum.prototype.decode = function (stream) {
      var index;
      index = (this || _global).type.decode(stream);
      return (this || _global).options[index] || index;
    };

    Enum.prototype.size = function () {
      return (this || _global).type.size();
    };

    Enum.prototype.encode = function (stream, val) {
      var index;
      index = (this || _global).options.indexOf(val);

      if (index === -1) {
        throw new Error("Unknown option in enum: " + val);
      }

      return (this || _global).type.encode(stream, index);
    };

    return Enum;
  }();

  exports = Enum;
}).call(exports);
export default exports;