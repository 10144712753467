import _Struct from "./Struct";

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};
// Generated by CoffeeScript 1.7.1
(function () {
  var Struct,
      VersionedStruct,
      __hasProp = {}.hasOwnProperty,
      __extends = function (child, parent) {
    for (var key in parent) {
      if (__hasProp.call(parent, key)) child[key] = parent[key];
    }

    function ctor() {
      (this || _global).constructor = child;
    }

    ctor.prototype = parent.prototype;
    child.prototype = new ctor();
    child.__super__ = parent.prototype;
    return child;
  };

  Struct = _Struct;

  VersionedStruct = function (_super) {
    __extends(VersionedStruct, _super);

    function VersionedStruct(type, versions) {
      (this || _global).type = type;
      (this || _global).versions = versions != null ? versions : {};

      if (typeof (this || _global).type === "string") {
        (this || _global).versionGetter = new Function("parent", "return parent." + (this || _global).type);
        (this || _global).versionSetter = new Function("parent", "version", "return parent." + (this || _global).type + " = version");
      }
    }

    VersionedStruct.prototype.decode = function (stream, parent, length) {
      var fields, res, _ref;

      if (length == null) {
        length = 0;
      }

      res = this._setup(stream, parent, length);

      if (typeof (this || _global).type === "string") {
        res.version = this.versionGetter(parent);
      } else {
        res.version = (this || _global).type.decode(stream);
      }

      if ((this || _global).versions.header) {
        this._parseFields(stream, res, (this || _global).versions.header);
      }

      fields = (this || _global).versions[res.version];

      if (fields == null) {
        throw new Error("Unknown version " + res.version);
      }

      if (fields instanceof VersionedStruct) {
        return fields.decode(stream, parent);
      }

      this._parseFields(stream, res, fields);

      if ((_ref = (this || _global).process) != null) {
        _ref.call(res, stream);
      }

      return res;
    };

    VersionedStruct.prototype.size = function (val, parent, includePointers) {
      var ctx, fields, key, size, type, _ref;

      if (includePointers == null) {
        includePointers = true;
      }

      if (!val) {
        throw new Error("Not a fixed size");
      }

      ctx = {
        parent: parent,
        val: val,
        pointerSize: 0
      };
      size = 0;

      if (typeof (this || _global).type !== "string") {
        size += (this || _global).type.size(val.version, ctx);
      }

      if ((this || _global).versions.header) {
        _ref = (this || _global).versions.header;

        for (key in _ref) {
          type = _ref[key];

          if (type.size != null) {
            size += type.size(val[key], ctx);
          }
        }
      }

      fields = (this || _global).versions[val.version];

      if (fields == null) {
        throw new Error("Unknown version " + val.version);
      }

      for (key in fields) {
        type = fields[key];

        if (type.size != null) {
          size += type.size(val[key], ctx);
        }
      }

      if (includePointers) {
        size += ctx.pointerSize;
      }

      return size;
    };

    VersionedStruct.prototype.encode = function (stream, val, parent) {
      var ctx, fields, i, key, ptr, type, _ref, _ref1;

      if ((_ref = (this || _global).preEncode) != null) {
        _ref.call(val, stream);
      }

      ctx = {
        pointers: [],
        startOffset: stream.pos,
        parent: parent,
        val: val,
        pointerSize: 0
      };
      ctx.pointerOffset = stream.pos + this.size(val, ctx, false);

      if (typeof (this || _global).type !== "string") {
        (this || _global).type.encode(stream, val.version);
      }

      if ((this || _global).versions.header) {
        _ref1 = (this || _global).versions.header;

        for (key in _ref1) {
          type = _ref1[key];

          if (type.encode != null) {
            type.encode(stream, val[key], ctx);
          }
        }
      }

      fields = (this || _global).versions[val.version];

      for (key in fields) {
        type = fields[key];

        if (type.encode != null) {
          type.encode(stream, val[key], ctx);
        }
      }

      i = 0;

      while (i < ctx.pointers.length) {
        ptr = ctx.pointers[i++];
        ptr.type.encode(stream, ptr.val, ptr.parent);
      }
    };

    return VersionedStruct;
  }(Struct);

  exports = VersionedStruct;
}).call(exports);
export default exports;